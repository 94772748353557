/**
 * Functions imported from JS into Ruffle.
 *
 * @ignore
 * @internal
 */
/**
 * Copies data into the given audio channel.
 * This is necessary because Safari does not support `AudioBuffer.copyToChannel`.
 *
 * @internal
 */
export function copyToAudioBuffer(audioBuffer, leftData, rightData) {
    if (leftData) {
        const dstBuffer = audioBuffer.getChannelData(0);
        dstBuffer.set(leftData);
    }
    if (rightData) {
        const dstBuffer = audioBuffer.getChannelData(1);
        dstBuffer.set(rightData);
    }
}
/**
 * Returns `AudioContext.getOutputTimestamp`, defaulting to `context.currentTime` if
 * `getOutputTimestamp` is unavailable. This is necessary because `web-sys` does not yet export
 * `AudioBuffer.copyToChannel`.
 *
 * @internal
 */
export function getAudioOutputTimestamp(context) {
    var _a, _b;
    const timestamp = (_a = context.getOutputTimestamp) === null || _a === void 0 ? void 0 : _a.call(context);
    return (_b = timestamp === null || timestamp === void 0 ? void 0 : timestamp.contextTime) !== null && _b !== void 0 ? _b : context.currentTime - context.baseLatency;
}
